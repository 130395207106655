.nav-underline .nav-link {
  color: #252b33;
}

.Header_nav_dropdown__1FAtP .nav-item:last-child {
  border: none;
}

@media screen and (max-width: 991px) {
  .Header_nav_dropdown__1FAtP .nav-item {
    border-bottom: 1px solid #e7e1e1;
  }
}

.section {
  overflow: hidden;
  padding: 6.5rem 0;
  position: relative;
}

.bg_section {
  background-color: #00fe9c;
}

.Header_nav_content__qQrtG .Header_nav_link__zuZao .nav-link:hover {
  border-bottom-color: #00fe9c;
  color: #000;
}

.Header_nav_content__qQrtG .Header_nav_link__zuZao .nav-link.active {
  border-bottom-color: #00fe9c;
  font-weight: 600;
}

.fs-18 {
  font-size: 18px;
  font-weight: 600;
}

.btn-download:hover .link-dark {
  color: #fff !important;
}

.text-1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
  text-align: center;
}

.box-icon {
  border-radius: 0;
  color: #4c4d4d;
  display: inline-block;
  font-size: 48px;
  margin-bottom: 0.8rem;
  margin-top: 0;
  min-height: 55px;
  min-width: 55px;
  padding: 0;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.text-6 {
  font-size: 1.5rem;
}

.text-7 {
  font-size: 1.75rem;
}

.project-modal .modal-content {
  border-radius: 0;
}

.project-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.project-modal .carousel-indicators {
  bottom: -15%;
}

.project-modal .carousel-indicators [data-bs-target] {
  background-color: #cdd4d2;
  border-radius: 50%;
  height: 10px;
  margin: 5px;
  width: 10px;
}

.project-modal .carousel-indicators .active {
  background-color: #00fe9c;
}

.project-modal .carousel-control-next-icon, .project-modal .carousel-control-prev-icon {
  color: #00fe9c !important;
  opacity: 0;
}

.project-modal .carousel:hover .carousel-control-next-icon {
  opacity: 1;
}

.project-modal .carousel:hover .carousel-control-prev-icon {
  opacity: 1;
}

#contact-form .is-invalid {
  box-shadow: none;
}

.form-control:focus {
  background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

@media screen and (max-width: 991px) {
  .Header_nav_content__qQrtG .Header_nav_link__zuZao .nav-link.active {
    color: #f5df4e;
    font-weight: 600;
  }

  .Header_nav_content__qQrtG .Header_nav_link__zuZao .nav-link:hover {
    color: #f5df4e;
  }
}