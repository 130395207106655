.icon {
  height: 60px;
  width: 60px;
}

@media screen and (max-width: 575px) {
  .icon {
    height: 35px;
    width: 35px;
  }
}