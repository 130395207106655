.wrapper_contact_section {
  background-color: #00fe9c;
}

.form_border {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(0,0,0,.12);
  border-radius: 0;
  color: #000;
  padding-left: 0!important;
}

.form_border:focus {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 2px solid rgba(0,0,0,.7);
  --webkit-box-shadow: none;
}

.submit_button {
  border-radius: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px 30px;
}
